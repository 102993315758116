import * as React from 'react';
import ReactDOM from 'react-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

// my imports
import { Autocomplete } from '@mui/material';
import { Auth, API } from 'aws-amplify';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';

import titles from '../titles.json';
import VideoEngineShaka from '../videoEngineShaka.js';

import { useEffect } from 'react';

import { Radio, RadioGroup } from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';

//date picker
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

import config from '../config.js';

import Modal from '@mui/material/Modal';

import PaymentMethods from '../stripe/PaymentMethods.js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
import {loadStripe} from '@stripe/stripe-js';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const childModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function Cameras(props) {
  const [searchedClubSelection, setClubSelection] = React.useState('');
  const [clubListApproved, setClubListApproved] = React.useState(undefined);
  const [cameraList, setCameraList] = React.useState([]);
  const [selectedCamera, setSelectedCamera] = React.useState(undefined);
  
   //can add async events in useEffects for APIs
  const [loading, setLoading] = React.useState(false);

  const [streamPlaybacks, setStreamPlaybacks] = React.useState(undefined);
  const [potentialDayCollisions, setPotentialDayCollisions] = React.useState(undefined);
  const [collision, setCollision] = React.useState(true);
  const [startDateTime, setStartDateTime] = React.useState('');

  const [openSubscriptionModal, setSubscriptionModaleOpen] = React.useState(false);
  const handleSubscriptionModalClose = () => setSubscriptionModaleOpen(false);
  const [openOneTimeModal, setOneTimeModaleOpen] = React.useState(false);
  const handleOneTimeModalClose = () => setOneTimeModaleOpen(false);
  const [displayPaymentMethodsView, setDisplayPaymentMethodsView] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState('');
  const [stripePromise, setStripePromise] = React.useState(null);

  const now = new Date;
  const maximumStartDate = now.setDate(now.getDate() + config.getEnvironmentConfig('production').maxDatePlayBack);

  useEffect(() => {
    setStripePromise(loadStripe(config.getEnvironmentConfig('development').stripePublishableKey))

    console.log(`now is ${now} max start date is ${maximumStartDate}`);
    console.log(`camera props ${JSON.stringify(props)}`);
    console.log(`home stripe promise is ${JSON.stringify(props.stripePromise)} with pub key ${config.getEnvironmentConfig('development').stripePublishableKey}`);
    if(loading) {
      return undefined
    }
    setLoading(true);

    (async () => {
      let fullClubList = await getClubList();
      console.log(`API sresponse club data is ${JSON.stringify(fullClubList)}`);
      var list = [];
      let i = 0;
      var sub = props.sub;
      while (i < fullClubList.length) {
        var memberState = stateOfMembership({id: fullClubList[i].clubId, label: fullClubList[i].clubName});
        if (memberState === 'approved') {
          var newItem = {cameras: fullClubList[i].cameras,clubId: fullClubList[i].clubId, clubName: fullClubList[i].clubName, address: fullClubList[i].address, phone: fullClubList[i].phone, isMember: memberState};
          list.push(newItem)  
        }
        i++;
      }
      console.log(`set club list called ${list}`);
      setClubListApproved(list);
    })();

    return () => {
      let a = null;
    };
  }, [loading], [props]);

  const getClubList = async event => {
    try {
      console.log("getting club list...");
      var path = `clubs?key1=na`;
      let clubData = await API.get("clubs", path);

      if (!!clubData) {
        console.log(`club data is ${JSON.stringify(clubData)}`);
        return clubData;
      } else {
        console.log("clubData came back empty");
      }
    } catch (e) {
      console.log("clubData error is: ", e);
        alert(e.message);
    }
  }

  const getStreamList = async (cloudStreamName) => {
    try {
      console.log("getting stream list...");
      var path = `videos?type=streamList&streamName=${cloudStreamName}&range=all`;
      let playbacks = await API.get("videos", path);

      if (!!playbacks) {
        console.log(`playbacks data is ${JSON.stringify(playbacks)}`);
        setStreamPlaybacks(playbacks);
        return playbacks;
      } else {
        console.log("playbacks came back empty");
      }
    } catch (e) {
      console.log("playbacks error is: ", e);
        alert(e.message);
    }
  }

  // value is {id, label} where label is name of club and id is clubid
  // clubInfo is {id: clubId, label: club name} where label is name of club and id is clubid. we go through users data to find the club id from their clubs array to determine state. if not present than has not requested access and we show a button.
  const handleClubSearchTextFieldChange = (clubInfo) => {
    console.log(` we have customerId ${JSON.stringify(props.userData.stripe.customerId)}`);
    setClubSelection(clubInfo.label);
    let i = 0;
    var found = false;
    while (i < clubListApproved.length && !found) {
      var id = clubListApproved[i].clubId;
      if (id === clubInfo.id) {
        found = true;
        setCameraList(clubListApproved[i].cameras)
      }
      i++;
    }
    if (!found) {
      console.log('not found')
      setCameraList([]);
    }
  }

  // input is only clubs user is a member of i.e. approved
  const makeClubsDropDownFromClubsData = (clubData) => {
    let i = 0;
    var dropDown = [];
    while (i < clubData.length) {
      var id = clubData[i].clubId;
      var label = clubData[i].clubName;
      var newItem = {id: id, label: label};
      dropDown.push(newItem)
      i++;
    }
    return dropDown;
  }

  // live to live is a issue
  const scheduleRecording = async () => {


    if (props.userData.stripe.subscription !== undefined) {
      console.log(` user is subscribed ? ${props.userData.stripe.subscriptionId}`);
      console.log(` we have customerId ${props.customerId}`);
      try {
        var path;
        console.log(`sending playback video clip... with starting time: ${startDateTime} on stream ${JSON.stringify(selectedCamera)}`);
        if (props.userData.promo !== undefined) {
          if (props.userData.promo === 'free') {
            path = `videos?startDateTime=${startDateTime}&streamName=${selectedCamera.streamName}&type=storePlayback&cameraName=${selectedCamera.name}&customerId=${props.userData.stripe.customerId}&promo=free`;
          }
        } else {
          path = `videos?startDateTime=${startDateTime}&streamName=${selectedCamera.streamName}&type=storePlayback&cameraName=${selectedCamera.name}&customerId=${props.userData.stripe.customerId}`;
        }
        
        console.log(`sending playback store request with path ${path}`);
        let response = await API.post("videos", path);
        console.log(`result ${JSON.stringify(response)}`);
        if (!!response) {
          console.log(`response is ${JSON.stringify(response)}`);
          alert('Its been scheduled');
        } else {
          console.log("response info came back empty");
          alert('Its been scheduled');
          setCollision(true);
        }
      } catch (e) {
        console.log("response error is: ", e);
          alert(e.message);
      }
    } else {
      console.log(`user not subscribed`);
      alert('Only subscribing members can schedule playbacks');
    }
  }

     // clubInfo is {id: clubId, label: club name} where label is name of club and id is clubId. we go through users data to find the club id from their clubs array to determine state. if not present than has not requested access and we show a button.
     const stateOfMembership = (clubInfo) => {
      //let user = await Auth.currentAuthenticatedUser();
      //let sub = user.attributes.sub;
      //console.log(`club: ${searchedClubSelection} club id is: ${clubInfo.id}`);
  
      var result = ''; // result could be '', approve, pending where '' means not yet requested
      let i = 0;
      console.log(`is member of club ${JSON.stringify(clubInfo)}`)
      while (i < props.userData.clubs.approved.length) {
        if (props.userData.clubs.approved[i] === clubInfo.id) {
          result = 'approved' 
        }
        i++;
      }
      i = 0;
      while (i < props.userData.clubs.pending.length) {
        if (props.userData.clubs.pending[i] === clubInfo.id) {
          result = 'pending' 
        }
        i++;
      }
      return result;
    }

    const switchCamera = (e) => {
      console.log(`switching camera 2${e.event.target.value}`);
      console.log(` props before checking subscription ${JSON.stringify(props)}`);
      if (props.userData.stripe.subscription !== undefined) {
        console.log(` user is subscribed ? ${props.userData.stripe.subscriptionId}`);
        let cam = {streamName: e.event.target.value, name: e.name}
        getStreamList(e.event.target.value);
        setSelectedCamera(cam);
      } else {
        console.log(` user is NOT subscribed `);
        /// adding TAC workaround if else. If checks some attribute on user i.e. promo = true
        if (props.userData.promo === 'free') {
          // TAC member
          console.log(` user is TAC member`);
          let cam = {streamName: e.event.target.value, name: e.name}
          getStreamList(e.event.target.value);
          setSelectedCamera(cam);
        }
        else {
          // not TAC member
          console.log(` user is NOT a TAC member`);
          setSubscriptionModaleOpen(true);
        }
        
      }   
    }

    const setRecordingTime = (e) => {
      console.log(`setRecordingTime  ${JSON.stringify(e)}`);
      const now = new Date();
      const eventDate = new Date(e.event);
      if (eventDate.getTime() >= now.getTime()) {
        setStartDateTime(eventDate.toISOString());
      }
      // find potential date collisions
      var i = 0;
      var potentialDayCollisions = [];
      let day = eventDate.getDate();
      let month = eventDate.getMonth() + 1;
      let year = eventDate.getFullYear();
      console.log(`selected day is ${day} month is ${month} and year is ${year}`);
      //console.log(`streamPlaybacks ${JSON.stringify(streamPlaybacks.startDateTime)}`);
      var dayTimeCollisonExists = false;
      while (i < streamPlaybacks.length) {   
        console.log(`AstreamPlaybacks startDateTime is  ${JSON.stringify(streamPlaybacks[i].startDateTime)}`);     
        var streamPlaybackDay = new Date(streamPlaybacks[i].startDateTime).getDate();
        var streamPlaybackMonth = new Date(streamPlaybacks[i].startDateTime).getMonth();
        var streamPlaybackYear = new Date(streamPlaybacks[i].startDateTime).getFullYear();
        console.log(`B streamPlaybacks[i] startDateTime day is ${streamPlaybackDay}, month is ${streamPlaybackMonth}, year is ${streamPlaybackYear}`);
        console.log(`C CHOSEN DATE startDateTime day is ${day}, month is ${month}, year is ${year}`);
        if (streamPlaybackDay === day && streamPlaybackMonth === month && streamPlaybackYear === year) {
          potentialDayCollisions.push(streamPlaybacks[i]);
        }
        // dayTimeCollisonExists: check if starttime selected is more than 40 min from the start time of every stream playback and 
        // start time selected is also more than 40 after every stream playback
        if ( diff_minutes(new Date(e.event), new Date(streamPlaybacks[i].startDateTime)) < 40) {
          dayTimeCollisonExists = true;
        }
        i++;
      }
      setCollision(dayTimeCollisonExists);
      setPotentialDayCollisions(potentialDayCollisions);
    }

    const diff_minutes =  (dt2, dt1) => {
      // Calculate the difference in milliseconds between the two provided dates and convert it to seconds
      var diff =(dt2.getTime() - dt1.getTime()) / 1000;
      // Convert the difference from seconds to minutes
      diff /= 60;
      // Return the absolute value of the rounded difference in minutes
      return Math.abs(Math.round(diff));
    }

    const showPaymentMethodsView = async () => {
      console.log('show cc view');
      try {
        var path;
        console.log(`getting client secret for setup intent `);
        path = `payments?type=setupIntent`;
        console.log(`sending setup intent request with path ${path}`);
        let response = await API.post("payments", path);
        console.log(`result ${JSON.stringify(response)}`);
        if (!!response) {
          console.log(`response is ${JSON.stringify(response)}`);
          if (response.client_secret !== '') {
            setClientSecret(response.client_secret);
            setDisplayPaymentMethodsView(true);
          }
        } else {
          console.log("response info came back empty");
        }
      } catch (e) {
        console.log("response error is: ", e);
          alert(e.message);
      }
    }

    function PaymentDetailsModal() {
      const [open, setOpen] = React.useState(false);
      const handleOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };
    
      return (
        <React.Fragment>
          <Button onClick={handleOpen}>Payment Details</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...childModalStyle, width: 400 }}>
              <h2 id="child-modal-title">Fees</h2>
              <p id="child-modal-description">
                We charge ${config.getEnvironmentConfig('production').subscriptionFee} per month. Your first monthly charge will be today. Each camera reservation costs ${config.getEnvironmentConfig('production').playbackFee} charged one time when the recording time begins.
              </p>
              <Button onClick={handleClose}>Close</Button>
            </Box>
          </Modal>
        </React.Fragment>
      );
    }

  const releaseModalFromCCView = () => {
    handleSubscriptionModalClose();
    setDisplayPaymentMethodsView(false);
  }

  return (
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={3}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Box component="main" sx={{ p: 3 }}>
              {
                props.topNavSelectedId === titles.app.services.topNavigatorTabs.cameras[0] ?
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <SearchIcon color="inherit" sx={{ display: 'block' }} />
                        </Grid>
                        <Grid item xs>
                          {
                            clubListApproved !== undefined ? 
                            <Autocomplete
                              disablePortal
                              disableClearable
                              id="club-selection"
                              options={makeClubsDropDownFromClubsData(clubListApproved)}
                              onChange={(event, value) => {console.log(`on change value ${JSON.stringify(value)}`); handleClubSearchTextFieldChange(value) }}
                              renderInput={(params) => <TextField variant="standard" fullWidth {...params} label="Club Name"  />}
                            /> :
                            <Typography>...</Typography>
                          }
                          
                        </Grid>
                    </Grid> :
                    null
              }
          </Box>
          {
            cameraList.length > 0 ?
            <Toolbar>
              <Modal
                open={openSubscriptionModal}
                onClose={handleSubscriptionModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                  {
                    displayPaymentMethodsView ? 
                    <div>
                      { clientSecret && stripePromise && <PaymentMethods secret={clientSecret} stripePromise={stripePromise} releaseModalFromCCView={() => releaseModalFromCCView()} />}
                      <button variant="primary" onClick={() => {handleSubscriptionModalClose(); setDisplayPaymentMethodsView(false)}}>Cancel</button>
                    </div> :
                    <div>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        Missing Subscription
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Subscription required to schedule. 
                        Would you like to subscribe?
                        <PaymentDetailsModal />
                      </Typography>
                      <ButtonGroup variant="text" aria-label="Basic button group">
                        <Button onClick={() => {  showPaymentMethodsView()}}>Yes</Button>
                        <Button onClick={() => {handleSubscriptionModalClose()}}>Later</Button>
                      </ButtonGroup>
                    </div>
                  }
                </Box>
              </Modal>
              <Modal
                open={openOneTimeModal}
                onClose={handleOneTimeModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                  {
                    <div>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        Notice of payment
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        A one time payment of $4 will be charged later when the scheduled recording starts. 
                        You can cancel the recording without incurring charges up until the start time.
                        Would you like to proceed?
                      </Typography>
                      <ButtonGroup variant="text" aria-label="Basic button group">
                        <Button onClick={() => {  console.log(`yes`); handleOneTimeModalClose(); scheduleRecording();}}>Yes</Button>
                        <Button onClick={() => { setSelectedCamera(undefined); handleOneTimeModalClose(); setCollision(true)}}>No</Button>
                      </ButtonGroup>
                    </div>
                  }
                </Box>
              </Modal>
              <Grid container spacing={2} m={1}>
                {
                  selectedCamera !== undefined ? 
                  <Grid item xs={12}>
                    {
                       selectedCamera !== undefined ?
                      <Typography variant="caption" display="block">
                        Selected: {selectedCamera.name}
                      </Typography> : null 
                    }
                    {
                       startDateTime !== '' ?
                      <Typography variant="caption" display="block">
                        Starting at: &nbsp;
                      {
                        new Date(startDateTime).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour: '2-digit', minute: '2-digit', hour12: true})
                      }
                      </Typography> : null 
                    }
                    <Grid item xs={6}>
                    {
                      collision === false && startDateTime !== '' ? <div><Typography variant="caption" display="block">Availability</Typography><CheckCircleOutlineIcon color={'success'} /> </div>: null
                    }
                </Grid>
                  </Grid> : null
                }
                <Grid item xs={6}>
                  <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      {cameraList.map(({ name, streamName}) => (
                        <FormControlLabel
                          value={streamName} 
                          control={
                            <Radio 
                              onChange={(e) => switchCamera({event: e, name: name})}
                              />
                            } 
                          label={
                            <Typography
                              style={{ wordWrap: "break-word" }}
                            >
                                Camera: {name}
                                {
                                  selectedCamera!= undefined && selectedCamera.name === name ? 
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateTimePicker']}>
                                      <DateTimePicker closeOnSelect={false} slotProps={{textField: {readOnly: true}}} disablePast maxDate={dayjs(maximumStartDate)} onChange={(e) => setRecordingTime({event: e})} label="Click calandar icon ->" />
                                    </DemoContainer>
                                  </LocalizationProvider> : null
                                }
                            </Typography>
                          }
                        />
                      ))}
                    </RadioGroup>
                    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                      { false && <Button color="secondary" onClick={() => {  scheduleRecording({mode: 'LIVE_REPLAY'})}}>{ titles.cameras.cameras.schedule}</Button> }
                        {
                          collision === false ? <Button color="warning" onClick={() => { setOneTimeModaleOpen(true)}}>{ titles.cameras.cameras.schedule}</Button> : null
                        }
                        { false && <Button onClick={() => {  scheduleRecording({mode: 'LIVE'})}}>{ titles.cameras.cameras.schedule}</Button> }
                    </Typography>
                </Grid>
              </Grid>
              <Grid>
                {
                  potentialDayCollisions !== undefined ?
                  <div>
                    <div>
                      <Typography>Slots to avoid</Typography>
                    </div>
                    {
                      potentialDayCollisions.map(({ startDateTime, endDateTime}) => (
                        <Box sx={{ border: 1, borderColor: 'error.main', m: 1, borderRadius: '4px' }}>

                        
                          <Typography color={'error'}>
                            {new Date(startDateTime).toLocaleDateString('en-us', {  month:"short", day:"numeric", hour: '2-digit', minute: '2-digit', hour12: true})}
                          </Typography>
                          </Box>
                      ))
                    }
                  </div> : null
                }
              </Grid>
            </Toolbar> :
            null
          }
      </AppBar>
      <Toolbar>
        {
          props.topNavSelectedId === titles.app.services.topNavigatorTabs.cameras[0] ?
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
            {titles.cameras.cameras.general}
          </Typography> : null
        }
      </Toolbar>
    </Paper>
  );
}
